import './Home.css';
import { Typewriter } from 'react-simple-typewriter';

const Home = () => {
  const handleDone = () => {};

  const handleType = (text) => {};

  return (
    <section className='home container'>
      <div className='home-wrapper'>
        <h2 className='home-header'>Mensajes de</h2>
        <h2 className='home-header'>
          <span className="whatsapp-color">WhatsApp</span> para
        </h2>
        <h2 className='type-header'>
          <Typewriter
            words={['Marketing', 'Notificaciones', 'Soporte', 'Ventas']}
            loop={1000}
            cursor
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            onLoopDone={handleDone}
            onType={handleType}
          />
        </h2>
        <p className='home-paragraph'>
          Mensajes de WhatsApp a de forma automática.
        </p>
        <div>
          <a
            href="/"
            className='work-with-us-button'
            onClick={(e) => {
              e.preventDefault();
              if (window.location.pathname === '/') {
                let element = document.getElementById('calculadora');
                element &&
                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
              } else {
                window.location.href = '/#about-us';
              }
            }}>
            Calcula tu Precio
          </a>
          <a
            href='/'
            className='learn-more-button'
            onClick={(e) => {
              let element = document.getElementById('traits');
              e.preventDefault();
              element &&
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          >
            Learn more
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
